<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import tagsGroupsResourcesActions from "@/calendesk/mixins/tagsGroupsResourcesActions";

export default {
  name: "GroupListButtons",
  components: { ListButtons },
  mixins: [tagsGroupsResourcesActions],
  props: {
    group: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [];

      if (this.loggedUserCanEditGroups) {
        actions.push({
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        });

        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryActions() {
      const actions = [];

      if (this.loggedUserCanEditGroups) {
        actions.push({
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        });
      }

      return actions;
    },
  },
  methods: {
    fire(action) {
      switch (action) {
        case "edit":
          this.editGroup(this.group);
          break;
        case "delete":
          this.handleRemoveGroups([this.group]);
          break;
      }
    },
  },
};
</script>
