<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";

export default {
  name: "GroupFilter",
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "group-list-filters",
      selectedFiltersSearch: null,
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersSearch: this.selectedFiltersSearch,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      return data;
    },
  },
};
</script>
