import { mapActions } from "vuex";
import dialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  mixins: [sharedActions, roleActions],
  methods: {
    ...mapActions({
      removeTag: "tag/delete",
      removeResource: "resource/delete",
      removeGroup: "group/delete",
      setRefreshTags: "tag/refreshTags",
      setRefreshResources: "resource/refreshResources",
      setRefreshGroups: "group/refreshGroups",
      setRefreshUsers: "user/refreshUsers",
      setRefreshBookings: "booking/refreshBookings",
      fetchAllTags: "tag/fetchAll",
      fetchAllGroups: "group/fetchAll",
      fetchAllResources: "resource/fetchAll",
    }),
    removeItems(ids, target) {
      this.setCommonDialogLoader(true);

      let promises = [];

      // Add the appropriate deletion promises based on target type
      switch (target) {
        case "tag":
          ids.forEach((id) => promises.push(this.removeTag(id)));
          break;
        case "resource":
          pushEvent("removeResource");
          ids.forEach((id) => promises.push(this.removeResource(id)));
          break;
        case "group":
          pushEvent("removeGroup");
          ids.forEach((id) => promises.push(this.removeGroup(id)));
          break;
      }

      // Execute all promises concurrently
      Promise.all(promises)
        .then(() => {
          // Clear cache and refresh data based on target type
          if (target === "tag") {
            this.clearCache("event-list-user");
            this.clearCache("event-list");
            this.clearCache("event-list-filters");
            this.setRefreshBookings();
            this.fetchAllTags();
          } else if (target === "resource") {
            this.fetchAllResources();
          } else if (target === "group") {
            this.clearCache("user-list-v2");
            this.clearCache("user-list-filters");
            this.setRefreshUsers();
            this.fetchAllGroups();
          }

          this.closeConfirmDialog();
        })
        .catch((error) => {
          errorNotification("", error);
        })
        .finally(() => {
          this.refreshTable(target);
          this.setCommonDialogLoader(false);
        });
    },
    async refreshTable(target) {
      switch (target) {
        case "tag":
          await this.setRefreshTags();
          break;
        case "resource":
          await this.setRefreshResources();
          break;
        case "group":
          await this.setRefreshGroups();
          break;
      }
    },
    handleAddNewItem(dialogType) {
      this.openConfirmDialog({
        type: dialogTypes[dialogType],
      });
    },
    editTag(data) {
      this.openConfirmDialog({
        type: dialogTypes.ADD_OR_UPDATE_TAG,
        data,
      });
    },
    editResource(data) {
      this.openConfirmDialog({
        type: dialogTypes.ADD_OR_UPDATE_RESOURCE,
        data,
      });
    },
    editGroup(data) {
      this.openConfirmDialog({
        type: dialogTypes.ADD_OR_UPDATE_GROUP,
        data,
      });
    },
    handleEdit(obj, target) {
      switch (target) {
        case "tag":
          this.editTag(obj);
          break;
        case "resource":
          this.editResource(obj);
          break;
        case "group":
          this.editGroup(obj);
          break;
      }
    },
    handleRemoveItem(item, target) {
      const obj = {};
      obj[target] = item.name;

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans(`remove_${target}`),
        warning: this.$trans(`remove_${target}_warning`, obj),
        importantInformation: this.$trans("no_way_to_undo_action"),
        confirmationText: this.$trans("remove"),
        confirmAction: () => this.removeItems([item.id], target),
      });
    },
    handleRemoveItems(items, target) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmationText: this.$trans("remove"),
        confirmAction: () =>
          this.removeItems(
            items.map((item) => item.id),
            target
          ),
      });
    },
    handleRemoveTags(items) {
      this.handleRemoveItems(items, "tag");
    },
    handleRemoveGroups(items) {
      this.handleRemoveItems(items, "group");
    },
    handleRemoveResources(items) {
      this.handleRemoveItems(items, "resource");
    },
  },
};
