<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @mouseleave="isHovered = false"
    @mouseover="isHovered = true"
    @click.stop="clickRow(item)"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__40-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("group") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ item.name }}
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("users_in_group") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
        class="text-center"
      >
        {{ item.number_of_users }}
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <group-list-buttons :is-hovered="isHovered" :group="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import tagsGroupsResourcesActions from "@/calendesk/mixins/tagsGroupsResourcesActions";
import GroupListButtons from "@/views/dashboard/pages/Settings/components/GroupListButtons.vue";

export default {
  components: {
    GroupListButtons,
  },
  mixins: [itemRowActions, tagsGroupsResourcesActions],
};
</script>
